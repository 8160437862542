.form-footer {
  display: flex;
  justify-content: flex-end;
}

.checkbox-inline {
  padding-top: 1.8rem;
}

.invalid-field {
  border: 2px solid #ea5455;
  border-color: #ea5455 !important;
}

.button-with-arrow {
  position: relative;

  &::after {
    position: absolute;
    content: '';

    height: 10px;
    width: 10px;

    top: 14px;
    right: 7px;
    transform: rotate(-130deg);

    border: 1px solid transparent;
    border-top-color: #3e3e3e;
    border-left-color: #3e3e3e;
    transition: top 0.3s, transform 0.3s;
  }

  &.active {
    &::after {
      top: 20px;
      transform: rotate(45deg);
    }
  }
}