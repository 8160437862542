/*** React Toggle ***/
// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../../core/variables/components-variables";

$switchIconColor: #464646;

.react-toggle-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .react-toggle,
  .react-toggle:hover:not(.react-toggle--disabled) {
    margin-right: 1rem;
    &:focus {
      outline: 0;
    }
    .react-toggle-track {
      background-color: $switch-bg-color;
      .react-toggle-track-x {
        path {
          fill: $switchIconColor;
        }
      }
      .react-toggle-track-check {
        path,
        polygon {
          stroke: $white;
        }
      }
    }
    &.react-toggle--checked {
      .react-toggle-track {
        background-color: $primary;
      }
    }
    .react-toggle-thumb {
      border: 0;
      background-color: $white;
      &:focus {
        outline: 0;
      }
    }
    &.react-toggle--focus {
      .react-toggle-thumb {
        box-shadow: none;
      }
    }
    &.switch-danger {
      .react-toggle-track {
        background-color: #640064;
        .react-toggle-track-x,
        .react-toggle-track-check {
          path {
            fill: $white;
          }
        }
      }
      &.react-toggle--checked {
        .react-toggle-track {
          background-color: #62ffb5;
        }
      }
    }
  }
}
