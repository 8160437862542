@import "~react-toggle/style.css";
@import "node_modules/prismjs/themes/prism-tomorrow";
@import "assets/scss/app.scss";
@import "assets/scss/components/form.scss";
@import "assets/scss/plugins/forms/switch/react-toggle.scss";

@import '~rc-slider/assets/index.css';
@import 'assets/scss/plugins/extensions/slider.scss';

@import "~flatpickr/dist/themes/light.css";
@import "../assets/scss/plugins/forms/flatpickr/flatpickr.scss";

.icon-minor {
    height: 15px;
    width: 15px;
}

.bg-rgba-green {
    background: rgba(1, 138, 122, 0.15) !important;
}

.green {
    color: #018a7a !important;
}

.icon-primary {
    color: $primary
}

.dd-btn-clear {
    background-color: transparent !important;
    padding: 0 !important;
    border: 0;

    &.btn-outline-primary {
        color: #002c67 !important;
    }
}

