.dd-m-modal {
  max-width: 800px;

  &-wrapper {
    max-width: 1200px;

    .card-img-top {
      height: 265px;
      width: 100%;
      object-fit: cover;
    }
  }
}
