// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../core/variables/components-variables";

.dropzone {
  min-height: 100px;
  border: 2px dashed $primary;
  background: $body-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: 0;
  }

  &.is-invalid {
    position: relative;
    border-color: #ea5455;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: -25px;
      height: 20px;
      width: 20px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }
  }
}
.dz-thumb {
  display: inline-flex;
  position: relative;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.button-remove {
  position: absolute;
  padding: 5px 10px;
  top: 5px;
  right: 5px;
}

.thumb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.dz-thumb-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}
.dz-img {
  display: block;
  width: 100%;
  height: 100%;
}
