@font-face {
    font-weight: 200;
    font-family: 'OpenSans';
    src:  url('../fonts/OpenSans-Light.woff2') format('woff2'),
          url('../fonts/OpenSans-Light.woff') format('woff');
}

@font-face {
    font-weight: 400;
    font-family: 'OpenSans';
    src:  url('../fonts/OpenSans-Regular.woff2') format('woff2'),
          url('../fonts/OpenSans-Regular.woff') format('woff');
}

@font-face {
    font-weight: 600;
    font-family: 'OpenSans';
    src:  url('../fonts/OpenSans-Bold.woff2') format('woff2'),
          url('../fonts/OpenSans-Bold.woff') format('woff');
}

.dd-align-middle {
  vertical-align: middle;
}
