.dd-toolbar-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.dd-toolbar-wrapper:focus {
  border-color: #002c67;
}

.rdw-editor-toolbar {
  border: 0 !important;
}

.dd-toolbar-editor {
  padding: 0 0.7rem;
}

.dd-has-select {
  z-index: 99;
  position: relative;
}
