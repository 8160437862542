.order-details-modal {
    z-index: 10000;
    position: absolute;
    height: 80vh;
    overflow: scroll;
    width: 25%;
    top: 0;
    right: -30%;
    border-radius: 0.5rem;
    background-color: $white;
    color: rgb(0,0,0);
    transition: right 0.5s ease-in-out;
    will-change: right;
    overflow-x: hidden;
    
    &.open-modal {
        right: 0;
        transition: right 1s ease-in-out;
    }

    .order-details-header {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 1rem;
        background-color: $cyan;
        color: $white;

        h3 {
            color: $white;
            font-size: 1rem;
        }

        .close-icon {
            position: absolute;
            right: 10px;
            top: 50%;
            cursor: pointer;
            padding: 8px;
            transform: translate(0, -50%);
        }
    }
    

    .wrap-client-name {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;

        border-bottom: 1px solid $gray-600;
        
    }

    .wrap-other-infos {
        padding: 2rem 1rem;
    }

    .client-info {
        // line-height: 0.5rem;
        font-size: 1.5em;
        letter-spacing: 0.1px;
    }

    .client-label {
        color: $gray-500;
        letter-spacing: 0.1px;
        line-height: 0.5rem;
        margin-bottom: 10px;
    }

    .wrap-client-other-infos {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    

}

.order-content-overlay{
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: block;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    
    &.show {
      visibility: visible;
      transition: all 0.3s ease;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem;
    }
}
.order-detail-row {
    cursor: pointer;
    transition: all .5s ease;
    &:hover {
        transform: translate(2px, -4px);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
}
